import React, { useEffect, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { AccountsContext } from "../context"
import { fetch_get } from "../../../../utils/fetch"
// import { sleep } from "../../../utils/common"
import * as Sentry from "@sentry/gatsby"
import _ from "lodash"
// import UserBarChart from "../charts/users-chart-bar"
import { FaRedoAlt, FaRegFileExcel } from "react-icons/fa"
import moment from "moment"
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa"
import Pagination from "@material-ui/lab/Pagination"
import { Select, MenuItem, Tooltip } from "@material-ui/core"

function PipelinesTabular(props) {
  let contextData = useContext(AccountsContext)
  const { getAccessTokenSilently } = useAuth0()
  var [show, setShow] = useState(false)
  var [dbRecords, setdbRecords] = useState([])
  var [loadStatus, setLoadStatus] = useState({ loading: false, error: false, errorMessage: null })
  const [pageCount, setPageCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchCounter, setSearchCounter] = useState(0)

  function onPageChange(event, page) {
    setCurrentPage(page)
    setSearchCounter(n => n + 1)
  }

  function onPageSizeChange(event) {
    const size = parseInt(event.target.value, 10)
    setPageSize(size)
    setCurrentPage(1)
    setSearchCounter(n => n + 1)
  }

  const LoadTotalUsersReport = async () => {
    // var orgCode = contextData.selectedParentOrganization.name

    setLoadStatus({ loading: true, error: false, errorMessage: null })
    var qs = `page=${currentPage}&limit=${pageSize}`

    getAccessTokenSilently()
      .then(accessToken =>
        // fetch_get(`admin/${orgCode}/total/users/report`, accessToken)
        fetch_get(`queue/mfa-reset/list?${qs}`, accessToken)
          .then(response => {
            if (response.status.data.length > 0) contextData.setHasRecords(true)
            setdbRecords(response.status.data)
            const totalRecords = response.status.pagination.totalRecords || 0

            setPageCount(Math.ceil(totalRecords / pageSize))
            setLoadStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
            Sentry.captureException(error)
            console.log(`Error while getting project links list: ${error}`)
          })
      )
      .catch(error => {
        setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
        Sentry.captureException(error)
        console.log(`Error while getting acdcess token: ${error}`)
      })
  }

  useEffect(() => {
    console.log(contextData)
    if (contextData.submitCounter === 0) return

    // console.log(contextData.selectedParentOrganization)
    setShow(true)
    LoadTotalUsersReport()
  }, [contextData.submitCounter])

  useEffect(() => {
    // console.log(contextData)
    if (searchCounter === 0) return
    setdbRecords([])

    setShow(true)
    LoadTotalUsersReport()
  }, [searchCounter])

  return (
    <React.Fragment>
      <>
        {show && (
          <>
            <div id="accounts-total-users-data">
              <div className="float-left">
                <h5>List of mfa reset request</h5>
              </div>
              <div className="float-right">
                <div className="form-inline">
                  <Pagination
                    count={pageCount}
                    page={currentPage}
                    onChange={onPageChange}
                    variant="outlined"
                    shape="rounded"
                  />

                  <Select
                    labelId="pageSize"
                    id="page-size"
                    value={pageSize}
                    onChange={onPageSizeChange}
                    className="pageSizeSelect"
                  >
                    <MenuItem value={10}>10 / page</MenuItem>
                    <MenuItem value={50}>50 / page</MenuItem>
                    <MenuItem value={100}>100 / page</MenuItem>
                  </Select>
                  <button
                    type="button"
                    className="btn btn-outline-dark btn-sm"
                    onClick={() => LoadTotalUsersReport()}
                  >
                    {/* <strong>Refresh</strong> */}
                    <FaRedoAlt />
                  </button>
                </div>
              </div>

              <br />
              <br />
              {/* <div>
                <div className="float-left">
                  <h5>List of jobs in queue</h5>
                </div>
              </div> */}

              <div>
                {loadStatus.loading && !loadStatus.error && (
                  <>
                    <div className="spinner-grow spinner-grow-sm" role="status"></div> Loading...
                  </>
                )}
                {!loadStatus.loading && !loadStatus.error && (
                  <>
                    {dbRecords.length === 0 && <div>Sorry ! No Records found</div>}
                    {dbRecords.length > 0 && (
                      <>
                        <div id="accounts-excel-users-data">
                          <span style={{ display: "none" }}>Pipelines Report</span>
                          <table className="table table-sm">
                            <thead>
                              <tr>
                                <th>User Email</th>
                                <th>Code</th>
                                <th>Code Used</th>
                                <th>On</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dbRecords.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.email}</td>
                                    <td>{item.code}</td>

                                    <td>
                                      {item.used && <>Yes</>}
                                      {!item.used && <>No</>}
                                    </td>

                                    <td>{item.created_date}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <div className="float-right">
                          <div className="form-inline">
                            <Pagination
                              count={pageCount}
                              page={currentPage}
                              onChange={onPageChange}
                              variant="outlined"
                              shape="rounded"
                            />

                            <Select
                              labelId="pageSize"
                              id="page-size"
                              value={pageSize}
                              onChange={onPageSizeChange}
                              className="pageSizeSelect"
                            >
                              <MenuItem value={10}>10 / page</MenuItem>
                              <MenuItem value={50}>50 / page</MenuItem>
                              <MenuItem value={100}>100 / page</MenuItem>
                            </Select>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                {!loadStatus.loading && loadStatus.error && (
                  <>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="alert alert-danger py-1" role="alert">
                      {loadStatus.errorMessage}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </>
    </React.Fragment>
  )
}

export default PipelinesTabular
