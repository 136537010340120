import React, { Component, useContext, useRef, useEffect } from "react"

import { AccountsContext } from "./context"
import QueueOptions from "./controls/queue-options"
import ProjectDeleteQueue from "./data/project-delete-queue-tabular"
import ProjectUserManagementQueue from "./data/project-user-management-queue-tabular"
import BEInvoice from "./data/be-invoice-tabular"
import MFAReset from "./data/mfa-reset-tabular"
import UserManagementQueue from "./data/user-management-queue-tabular"
import ProjectArtifactsUploadQueue from "./data/project-artifacts-upload-tabular"
import ProjectBundleEntries from "./data/project-bundle-entries-tabular"
import CIPipelines from "./data/ci-pipelines-tabular"
import CIJObs from "./data/ci-jobs-tabular"
import _ from "lodash"

function AccountsIndex(props) {
  let contextData = useContext(AccountsContext)

  const getSelectedQueueOption = () => {
    if (_.isNil(contextData)) return null
    if (_.isNil(contextData.selectedQueueOption)) return null
    return contextData.selectedQueueOption
  }
  return (
    <React.Fragment>
      <QueueOptions />
      <hr />

      {getSelectedQueueOption() === "project-delete-queue" && (
        <>
          <div>
            <ProjectDeleteQueue />
          </div>
        </>
      )}
      {getSelectedQueueOption() === "project-user-management-queue" && (
        <>
          <div>
            <ProjectUserManagementQueue />
          </div>
        </>
      )}
      {getSelectedQueueOption() === "be-invoice-queue" && (
        <>
          <div>
            <BEInvoice />
          </div>
        </>
      )}
      {getSelectedQueueOption() === "mfa-reset" && (
        <>
          <div>
            <MFAReset />
          </div>
        </>
      )}
      {getSelectedQueueOption() === "user-management-queue" && (
        <>
          <div>
            <UserManagementQueue />
          </div>
        </>
      )}
      {getSelectedQueueOption() === "project-artifacts-upload-queue" && (
        <>
          <div>
            <ProjectArtifactsUploadQueue />
          </div>
        </>
      )}
      {getSelectedQueueOption() === "project-bundle-entries" && (
        <>
          <div>
            <ProjectBundleEntries />
          </div>
        </>
      )}
      {getSelectedQueueOption() === "ci-pipelines" && (
        <>
          <div>
            <CIPipelines />
          </div>
        </>
      )}
      {getSelectedQueueOption() === "ci-jobs" && (
        <>
          <div>
            <CIJObs />
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default AccountsIndex
