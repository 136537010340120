import React from "react"
import { Container } from "reactstrap"

const Content = ({ children }) => (
  <Container fluid>
    <Container>{children}</Container>
  </Container>
)

export default Content
