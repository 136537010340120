import React, { createContext, useEffect, useState } from "react"
import moment from "moment"
export const AccountsContext = createContext()

export const AccountsContextContextProvider = ({ children }) => {
  var [selectedQueueOption, setSelectedQueueOption] = React.useState(null)
  var [hasRecords, setHasRecords] = React.useState(false)
  var [pdfExportStarted, setPdfExportStarted] = React.useState(false)
  var [submitCounter, setSubmitCounter] = React.useState(0)
  return (
    <AccountsContext.Provider
      value={{
        selectedQueueOption,
        setSelectedQueueOption,
        hasRecords,
        setHasRecords,
        pdfExportStarted,
        setPdfExportStarted,
        submitCounter,
        setSubmitCounter,
      }}
    >
      {children}
    </AccountsContext.Provider>
  )
}
