import React, { useEffect, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { AccountsContext } from "../context"

import _ from "lodash"

function ProjectLinksList(props) {
  let contextData = useContext(AccountsContext)

  var [queueOptions, setQueueOptions] = useState([
    "project-delete-queue",
    "project-user-management-queue",
    "be-invoice-queue",
    "mfa-reset",
    "user-management-queue",
    "project-artifacts-upload-queue",
    "project-bundle-entries",
    "ci-pipelines",
    "ci-jobs",
  ])

  const hande_parentOrganization_change = e => {
    // console.log(parentOrganizations, e.target.value)
    let searchRes = queueOptions.filter(function (item) {
      return e.target.value === item
    })
    // console.log("res", searchRes)
    contextData.setSelectedQueueOption(searchRes[0])
  }

  const handle_submit = () => {
    contextData.setSubmitCounter(n => n + 1)
  }

  const getSelectedQueueOption = () => {
    if (_.isNil(contextData.selectedQueueOption)) return null
    return contextData.selectedQueueOption
  }

  useEffect(() => {
    // LoadParentOrganizations()
    contextData.setSelectedQueueOption(queueOptions[0])
  }, [])

  return (
    <React.Fragment>
      <>
        <form className="ml-2">
          <div className="form-row">
            <div className="col-2">
              <select
                className="form-control form-control-sm"
                value={getSelectedQueueOption()}
                aria-label="Default select example"
                name="copyRightHolderOption"
                onChange={e => hande_parentOrganization_change(e)}
              >
                {queueOptions.map((p, index) => (
                  <option key={"qq" + index} value={p}>
                    {p}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-3">
              <button
                type="button"
                className="btn btn-success btn-sm"
                onClick={() => handle_submit()}
              >
                <strong>submit</strong>
              </button>
            </div>
          </div>
        </form>
      </>
    </React.Fragment>
  )
}

export default ProjectLinksList
